import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";

const routes = [
    {
        path: '/',
        redirect: '/landing',
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: () => import("@/views/pages/LandingPage"),
    },
    {
        path: '/how-to-win',
        name: 'HowToWin',
        component: () => import("@/views/pages/HowToWin"),
    },
    {
        path: '/rewards',
        name: 'RewardsPage',
        component: () => import("@/views/pages/RewardsPage"),
    },
    {
        path: '/play',
        name: 'QuestionPage',
        component: () => import("@/views/pages/QuestionPage"),
    },
    {
        path: '/upsell',
        name: 'UpsellPage',
        component: () => import("@/views/pages/UpsellPage"),
    },
    {
        path: '/next-day',
        name: 'DailyWaitPage',
        component: () => import("@/views/pages/DailyWaitPage"),
    },
    {
        path: '/spin',
        name: 'SpinToClaimReward',
        component: () => import("@/views/pages/SpinToClaimReward"),
    },
    {
        path: '/network-error',
        name: 'NetworkError',
        component: () => import("@/views/pages/NetworkError"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

export default router
