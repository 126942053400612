<template>
  <div v-if="blurBackground(this.$route)" class="blur"></div>
  <div class="d-flex flex-column text-center header-container">
    <img loading="eager" decoding="async" v-if="isLogoVisible(this.$route)" class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-4 daily-five-logo" src="../../assets/logo-daily-trivia-five.png">
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeBlur: undefined
    }
  },
  methods: {
    isLogoVisible(route) {
      return !(route.path === '/landing' || route.path === '/network-error');
    },
    blurBackground(route) {
      return route.path === '/upsell';
    }
  },
}
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #FFFFFF;
  font-family: "FatFrank", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-text-stroke: .6px black;
}

.daily-five-logo {
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
}

.blur {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(1px);
  top: 0;
  height: 140vh;
  width: 100vh;
  z-index: 1;
}
</style>
