import axios from 'axios'
import {environmentConfig} from "@/config/enviromentConfig";

const productId = 25;

export default class TriviaService {
    static async getGameState(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/get-game-state`, {
            msisdn,
            productId: productId,
        })
    }

    static async pauseGame(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/pause-game`, {
            msisdn,
            productId: productId,
        })
    }

    static async getTriviaQuestions(msisdn, isBonusRound) {
        const response = await axios.post(`${environmentConfig.triviaService}/get-user-questions`, {
            msisdn: msisdn,
            amountOfQuestions: 1,
            questionCategoryId: null,
            difficulty: null,
            productId: productId,
            bonusGame: isBonusRound ? 1 : 0,
        })
        return response.data[0];
    }

    static async saveUserAnswer(answer, gameId, isBonusRound) {
        const response = await axios.post(`${environmentConfig.triviaService}/save-user-answers`, {
            answer: answer,
            productId: productId,
            gameId: gameId,
            bonusGame: isBonusRound ? 1 : 0,
        })
        return response.data.recordsets;
    }

    static async startGame(msisdn, gameId, bonusGame) {
        return await axios.post(`${environmentConfig.triviaService}/start-game`, {
            msisdn,
            gameId,
            bonusGame,
        })
    }

    static async getUserGameFunds(msisdn) {
        const response = await axios.post(`${environmentConfig.triviaService}/get-user-game-funds`, {
                msisdn,
                productId: productId,
            },
        )
        return response.data;
    }

    static async spinPrizeWheel(msisdn, userSpinIndex, gameFundsId, firstTimePlayer) {
        return await axios.post(`${environmentConfig.triviaService}/spin-prize-wheel-daily-five`, {
                msisdn,
                userSpinIndex,
                gameFundsId,
                firstTimePlayer
            },
        )
    }

    static async awardInstantWinner(msisdn) {
        return await axios.post(`${environmentConfig.triviaService}/award-instant-daily-five-winner`, {
                msisdn,
            },
        )
    }
}