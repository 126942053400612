import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        user: undefined,
        quizCompleted: undefined,
        gameState: {GameComplete: false},
        correctAnswers: undefined,
        allocatedSpins: undefined,
        firstTimeUser : undefined,
        voucherPopUp : undefined,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setUser(state, user) {
            state.user = user
        },
        setGameState(state, gameState) {
            if (gameState) state.gameState = gameState;
            else state.gameState = {GameComplete: false};
        },
        setCorrectAnswers(state, correctAnswers) {
            state.correctAnswers = correctAnswers
        },
        setAllocatedSpins(state, allocatedSpins) {
            state.allocatedSpins = allocatedSpins
        },
        setFirstTimeUser(state, firstTimeUser) {
            state.firstTimeUser = firstTimeUser
        },
        setVoucherPopup(state, voucherPopUp) {
            state.voucherPopUp = voucherPopUp
        },
    },
    actions: {},
    modules: {}
})
