import store from "@/store";
import DcbService from "@/services/dcbService";
import TriviaService from "@/services/triviaService";
import UpstreamService from "@/services/upstreamService";
import {URLSearchParams} from "core-js/modules/web.url-search-params.constructor";

export async function userAuthenticate(to, from, next) {
    if (store.state.user) return next();

    store.commit('setIsLoading', true);

    const urlParams = new URLSearchParams(window.location.search);
    const msisdn = setMsisdn(urlParams);

    const vodacomUser = (await UpstreamService.checkUserOnNet(msisdn)).data;

    if (!vodacomUser) {
        next('/network-error');
    }

    if (msisdn) {
        await fetchAndUpdateUser(msisdn, next);
    }
}

function setMsisdn(urlParams) {
    const userMsisdn = urlParams.get('msisdn');
    const localMsisdn = localStorage.getItem('user');

    let msisdn;

    if (userMsisdn) {
        msisdn = userMsisdn;
    } else if (localMsisdn) {
        msisdn = localMsisdn;
    }

    return msisdn;
}

function setUser(user) {
    store.commit('setUser', user);
    localStorage.setItem('user', user.msisdn);
}

function setFirsTimeUser(boolean) {
    store.commit('setFirstTimeUser', boolean);
}

async function fetchAndUpdateUser(msisdn, next) {
    const user = (await DcbService.getUserViaProductId(`${msisdn}`)).data;
    setUser(user);
    await updateUserGateState(msisdn, next);
}

export async function updateUserGateState(msisdn, next) {
    const response = (await TriviaService.getGameState(msisdn)).data.recordsets;
    setFirsTimeUser(response[1][0].NewPlayer);
    redirectBasedOnPlayedStatus(next);
}

function redirectBasedOnPlayedStatus(next) {
    const path = store.state.gameState.GameComplete ? '/next-day' : '/landing';
    store.commit('setIsLoading', false);
    next({path});
}


