import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class DcbService {
    static async getUserViaProductId(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/get-user-via-product-id`, {
            msisdn,
            productId: 5,
            externalRef: 1233564,
        }, {
            headers: {
                Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
            }
        })
    }

    static async encryptUserMsisdn(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/encrypt-msisdn`, {
                msisdn: msisdn,
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }

    static async getUserServices(msisdn) {
        return await axios.post(`${environmentConfig.dcbService}/get-user-services`, {
                msisdn: msisdn,
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }

    static async doiOne(msisdn, service) {
        return await axios.post(`${environmentConfig.dcbService}/doi-one`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web"
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }

    static async doiTwo(msisdn, service) {
        return await axios.post(`${environmentConfig.dcbService}/doi-two`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web"
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }

    static async subscribeUser(msisdn, serviceId, campaignId) {
        return await axios.post(`${environmentConfig.dcbService}/subscribe`, {
                msisdn,
                serviceId,
                externalRef: 12335642,
                campaignId: campaignId,
                acquisitionChannel: 'Organic-Web',
            }, {
                headers: {
                    Authorization: 'Basic UGVucm9zZV9HYW1pbmc6Qm83c2pIeVQ4MGdB'
                }
            }
        )
    }
}
