import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";

export default class UpstreamService {
    static async checkUserOnNet(msisdn) {
        return await axios.post(`${environmentConfig.upstreamService}/check-user-on-net`, {
            msisdn
        })
    }
}
