export const productionConfig = {
    vodaPayIntegrationHost: `${process.env.VUE_APP_VODAPAY_INTEGRATION_HOST}`,
    dcbService: `${process.env.VUE_APP_DCB_INTEGRATION_HOST}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    stopClockRedirectUrl: `${process.env.VUE_APP_STOP_CLOCK_REDIRECT_URL}`,
    unlockThreeRedirectUrl: `${process.env.VUE_APP_UNLOCK_THREE_REDIRECT_URL}`,
    headToHeadRedirectUrl: `${process.env.VUE_APP_HEAD_TO_HEAD_REDIRECT_URL}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    upsellStcCampaignId: `${process.env.VUE_APP_STOP_CLOCK_SUB_CAMPAIGN_ID}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADER}`,
}

export const stagingConfig = {
    vodaPayIntegrationHost: `${process.env.VUE_APP_VODAPAY_INTEGRATION_HOST}`,
    dcbService: `${process.env.VUE_APP_DCB_INTEGRATION_HOST}`,
    triviaService: `${process.env.VUE_APP_TRIVIA_SERVICE}`,
    upstreamService: `${process.env.VUE_APP_UPSTREAM_SERVICE}`,
    stopClockRedirectUrl: `${process.env.VUE_APP_STOP_CLOCK_REDIRECT_URL}`,
    unlockThreeRedirectUrl: `${process.env.VUE_APP_UNLOCK_THREE_REDIRECT_URL}`,
    headToHeadRedirectUrl: `${process.env.VUE_APP_HEAD_TO_HEAD_REDIRECT_URL}`,
    quizIncRedirectUrl: `${process.env.VUE_APP_QUIZ_INC_REDIRECT_URL}`,
    upsellStcCampaignId: `${process.env.VUE_APP_STOP_CLOCK_SUB_CAMPAIGN_ID}`,
    campaignHubService: `${process.env.VUE_APP_CAMPAIGN_HUB_SERVICE}`,
    quizIncTermsConditionsHub: `${process.env.VUE_APP_QUIZ_INC_TERMS_CONDITIONS_URL}`,
    authHeader: `${process.env.VUE_APP_AUTHORIZATION_HEADER}`,
}

const environment = getEnvironment()

export const environmentConfig = getEnvObject();

function getEnvironment() {
    let base_url = window.location.origin;
    if (base_url.includes('staging')) return 'staging'
    if (base_url.includes('localhost')) return 'development'
    else return 'production'
}

function getEnvObject() {
    if (environment === 'staging') return stagingConfig
    if (environment === 'development') return productionConfig
    if (environment === 'production') return productionConfig
}
